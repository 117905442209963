@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;400;500;700&display=swap");

body {
    font-family: "Poppins", sans-serif !important;
}

/* Change the white to any color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.status-badge {
    padding: 2px 10px;
    border-radius: 10px;
    border: 1px solid gray;
    background-color: aqua;
    color: black;
    max-width: min-content;
    font: 10px;
}

.active {
    background-color: rgb(141, 245, 141);
}

.inactive {
    background-color: rgb(245, 141, 141);
}

.pending {
    background-color: rgb(245, 245, 141);
}