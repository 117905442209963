table {
    thead {
        th {
            border-top: 0px !important;
        }
    }
}

$base-line-height: 18px;
$white: rgb(255, 255, 255);
$off-white: rgba($white, 0.2);
$spin-duration: 1s;
$pulse-duration: 750ms;

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@keyframes pulse {
    50% {
        background: $white;
    }
}

.loading {
    border-radius: 50%;
    width: $base-line-height;
    height: $base-line-height;
    border: 0.15rem solid $off-white;
    border-top-color: $white;
    animation: spin $spin-duration infinite linear;
}
